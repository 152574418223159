/* ----------------------------------------------------------------------------
sound player with visualizer
---------------------------------------------------------------------------- */
import {
  CanvasSpace, Sound,
} from 'pts';

export default class SoundPlayer extends HTMLElement {
  constructor() {
    super();
  }

  load() {
    this.gfxElement = document.createElement('div');
    this.gfxElement.classList.add('noise__visualizer');
    this.prepend(this.gfxElement);
    const elementStyles = getComputedStyle(this.gfxElement);
    const color = elementStyles.getPropertyValue('--color');
    this.space = new CanvasSpace(this.gfxElement);
    this.form = this.space.getForm();
    this.bins = 128;
    this.space.setup({ bgcolor: 'rgba(0, 0, 0, 0)', autoResize: true });

    Sound.loadAsBuffer(this.audio.getAttribute('src')).then((s) => {
      this.sound = s;
      this.space.playOnce(50);
      this.bufferLoaded = true;
    }).catch((e) => console.error(e));

    this.space.add({
      animate: (time, ftime) => {
        if (this.sound && this.sound.playable) {
          this.sound.freqDomainTo(this.space.size).forEach((t, i) => {
            this.form.fillOnly(color).point(t, 2);
          });
          // loop if the sound is at the end and the button is pressed
          if (this.sound.playing === false && this.toggle.getAttribute('aria-pressed') === 'true') {
            this.toggleSound();
          }
        }
      },
    });

    this.space.bindMouse().bindTouch().play();
  }

  toggleSound() {
    if (this.sound.playing || !this.bufferLoaded) {
      this.sound.stop();
    } else {
      // recreate buffer again
      this.sound.createBuffer().analyze(this.bins);
      this.sound.start();
      this.space.replay();
    }
  }

  playable() {
    this.toggle.addEventListener('click', (e) => {
      const pressed = e.target.getAttribute('aria-pressed') === 'true';
      e.target.setAttribute('aria-pressed', String(!pressed));
      this.classList.toggle('--active');
      // make sure sound is loaded before playing
      if (!this.sound) {
        this.load();
      } else {
        this.toggleSound();
      }
    });
  }

  connectedCallback() {
    this.toggle = this.querySelector('button');
    this.audio = this.querySelector('audio');
    this.bufferLoaded = false;
    this.load();
    this.playable();
  }
}

customElements.define('sound-player', SoundPlayer);
